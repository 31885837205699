import React from "react"

interface Props {
  title: string
  description: string
  image: string
}

const defaultProps = {}

const ArticleShare: React.FC<Props> = props => {
  // console.log("ArticleShare :", props)

  let pageUrl = ""
  if (typeof window !== "undefined") {
    pageUrl = encodeURIComponent(window.location.href)
  }

  const openSocialWindow = (url: string) => {
    var left = (screen.width - 570) / 2,
      top = (screen.height - 570) / 2,
      params =
        "menubar=no,toolbar=no,status=no,width=570,height=570,top=" +
        top +
        ",left=" +
        left
    // Setting 'params' to an empty string will launch content in a new tab or window rather than a pop-up.
    // params = "";
    window.open(url, "NewWindow", params)
  }

  return (
    <section className="article-share">
      <div className="row">
        <div className="column medium-8 xlarge-7 xlarge-push-2">
          <h5 className="article-share__label">Share this</h5>
          <div className="social-share">
            {/* Facebook */}
            <button
              className="social-share__link st-custom-button share-btn nojs-hidden"
              title="Facebook"
              data-network="facebook"
              onClick={() =>
                openSocialWindow(
                  `https://www.facebook.com/sharer.php?u=${pageUrl}`
                )
              }
            >
              <svg className="svg-icon svg-facebook" viewBox="0 0 1024 1024" fill="#fff">
                <use href="#facebook"/>
              </svg>
            </button>

            {/* Pinterest */}
            <button
              className="social-share__link st-custom-button share-btn nojs-hidden"
              title="Pinterest"
              data-network="pinterest"
              onClick={() =>
                openSocialWindow(
                  `http://pinterest.com/pin/create/button/?url=${pageUrl}&media=${props.image}&description=${props.description}`
                )
              }
            >
              <svg className="svg-icon svg-pinterest" viewBox="0 0 1792 1792" fill="#fff">
                <use href="#pinterest"/>
              </svg>
            </button>

            {/* Twitter */}
            <button
              className="social-share__link st-custom-button share-btn nojs-hidden"
              title="Twitter"
              data-network="twitter"
              onClick={() =>
                openSocialWindow(
                  `https://twitter.com/intent/tweet?url=${pageUrl}`
                )
              }
            >
              <svg className="svg-icon svg-twitter" viewBox="0 0 1792 1792" fill="#fff">
                <use href="#twitter"/>
              </svg>
            </button>

            {/* Email */}
            <a
              href={`mailto:?subject=${props.title}&body=${pageUrl}`}
              className="social-share__link st-custom-button share-btn"
            >
              <svg className="svg-icon svg-email" viewBox="0 0 1792 1792" fill="#fff">
                <use href="#email"/>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

ArticleShare.defaultProps = defaultProps

export default ArticleShare
