import React from "react"
import { Link } from "gatsby"

const SectionHeader = React.lazy(() => import("../SectionHeader"))
import ArticleCard from "../../CardTypes/ArticleCard"

import { useArticles, useDictionary, useItineraries } from "../../../hooks"
import {
  buildQuerySet,
  getRandomListingItems,
  queryListingItems,
} from "../../../utils/utils_query"
import { PageModels } from "../../../models/pages"
import { ArticleListingModule } from "../../../models/modules"
import { ListingItemModel } from "../../../models/common"
import { getLangUrl } from "../../../utils/utils_links"

interface Props extends ArticleListingModule {}

const defaultProps = {}

interface ListingDataModel {
  initialItems?: ListingItemModel[]
  filteredItems?: ListingItemModel[]
  results?: ListingItemModel[]
  page?: number
  loading?: boolean
}

// interface SelectionModel {
//   [index: string]: OptionModel[]
// }

const ArticleListing: React.FC<Props> = props => {
  //console.log("ArticleListing: ", props)
  const articleList = useArticles(props.locale || "en-au")
  const itinerariesList = useItineraries(props.locale || "en-au")

  // state

  const listingData: ListingDataModel = {
    initialItems: undefined,
    filteredItems: [],
    results: undefined,
    page: undefined,
    loading: true,
  }

  // defaults values
  const pageLoad =
    props.number_of_rows && props.number_of_rows > 0
      ? props.number_of_rows * 3
      : 6

  // build query steps
  const querySet = buildQuerySet(props.set)
  //console.log("querySet: ", querySet)

  // include exclude
  const include =
    props.include_items?.map(item => item.productId || item.uid) || []
  const exclude =
    props.exclude_items?.map(item => item.productId || item.uid) || []

  // get content sources
  const articles = articleList
    .map((page: PageModels) =>
      queryListingItems(page, querySet, include, exclude, props.locale)
    )
    .filter(Boolean)
  const itineraries = itinerariesList
    .map((page: PageModels) =>
      queryListingItems(page, querySet, include, exclude, props.locale)
    )
    .filter(Boolean)
  // combine and sort
  const listingSource = [...articles, ...itineraries]
  //console.log("listingSource: ", listingSource)
  //console.log("=======================")
  listingData.initialItems = listingSource.sort(
    (a, b) => include.indexOf(a.id) - include.indexOf(b.id)
  )
  // filter results on selection change
  if (listingData.initialItems.length < pageLoad) {
    if (
      props.enable_smart_display_for_articles &&
      props.enable_smart_display_for_itineraries
    ) {
      // fill with halfish articles and then the rest itineraries
      listingData.initialItems = [
        ...listingData.initialItems,
        ...getRandomListingItems(
          articleList,
          Math.round(pageLoad - listingData.initialItems.length / 2),
          props.locale
        ),
      ]

      listingData.initialItems = [
        ...listingData.initialItems,
        ...getRandomListingItems(
          itinerariesList,
          pageLoad - listingData.initialItems.length,
          props.locale
        ),
      ]
    }
    if (
      !props.enable_smart_display_for_itineraries &&
      props.enable_smart_display_for_articles
    ) {
      listingData.initialItems = [
        ...listingData.initialItems,
        ...getRandomListingItems(
          articleList,
          pageLoad - listingData.initialItems.length,
          props.locale
        ),
      ]
    }
    if (
      props.enable_smart_display_for_itineraries &&
      !props.enable_smart_display_for_articles
    ) {
      listingData.initialItems = [
        ...listingData.initialItems,
        ...getRandomListingItems(
          itinerariesList,
          pageLoad - listingData.initialItems.length,
          props.locale
        ),
      ]
    }
  }
  listingData.initialItems = listingData.initialItems.slice(0, pageLoad)

  return (
    <>
      <section className="more-articles">
        {(props.heading || props.intro_paragraph) && (
          <SectionHeader
            heading={props.heading}
            intro={props.intro_paragraph}
          />
        )}

        {/* listing */}
        <div className="row small-10 large-8 xlarge-12 small-up-1 xlarge-up-3 more-articles__list">
          {listingData.initialItems?.map((item, index) => (
            <div key={`article_${index}`} className="column article">
              <ArticleCard key={`item_${index}`} {...item} />
            </div>
          ))}
        </div>

        {/* cta */}
        <div className="row">
          <div className="column small-10 small-centered btn-row__wrapper ">
            <a
              href={
                getLangUrl(props.locale) + props.read_more_url ||
                getLangUrl(props.locale) + "/articles"
              }
              className="button btn-row__button"
            >
              {props.read_more_text ||
                useDictionary("Read more articles", props.locale)}
            </a>
          </div>
        </div>
      </section>
    </>
  )
}

ArticleListing.defaultProps = defaultProps

export default ArticleListing
