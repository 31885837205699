import React, { useEffect } from "react"
import { graphql, HeadProps } from "gatsby"
import get from "lodash/get"

import { useDispatch } from "react-redux"
import { setBreadcrumbs } from "../state/site"

import Seo from "../components/Seo"
import Hero from "../components/Hero/Hero"
import PageIntro from "../components/PageIntro"
import RenderModularBlock from "../components/RenderModularBlock"
import Author from "../components/Author/Author"

import {
  buildBreadcrumbs,
  getLangUrl,
  getPagePath,
  getPageUrl,
  getPrettyURL,
} from "../utils/utils_links"
import { getFirstArrayItem } from "../utils/utils_arrays"
import { processModules } from "../utils/utils_modules"
import { ItineraryPageModel, PageModel } from "../models/pages"
import { SeoDataModel } from "../models/common"
import { MiniMapModule, TextAreaModule } from "../models/modules"
import parse from "html-react-parser"
import { cleanObj, getPageRegion } from "../utils/utils"
import { BUILD_LANGS } from "../utils/constants"
import ArticleShare from "../components/ArticleShare/ArticleShare"
import ArticleListing from "../components/ModularBlocks/ArticleListing/ArticleListing"
import { useDictionary, useItineraries } from "../hooks"
import { queryRelatedItems } from "../utils/utils_query"
import { getImageSrc } from "../utils/utils_images"
import { getSrc } from "gatsby-plugin-image"

const defaultProps = {}

export const ItineraryPage = (props: ItineraryPageModel) => {
  // page data
  const page: ItineraryPageModel = get(
    props,
    "data.page"
  ) as any as ItineraryPageModel
  // console.log("ItineraryPage: ", page)

  const itineraryList = useItineraries(page.publish_details?.locale)

  const seoData: SeoDataModel = {
    slug: getPrettyURL(page),
    metaTitle: page.seo?.meta_title || page.heading,
    metaTitlePipe: undefined,
    metaDescription: page.seo?.meta_description || page.intro,
    metaKeywords: page.seo?.keywords,
    shareImage:
      page.seo?.share_image || getFirstArrayItem(page.hero_image?.banners)?.banner?.image.file,
    shareTitle: page.seo?.social_share_title,
    shareDescription: page.seo?.social_share_description,
    locale: page.publish_details?.locale,
    hidden: page.seo?.hidden || false,
  }
  // redux
  const dispatch = useDispatch()
  dispatch(setBreadcrumbs(buildBreadcrumbs(page)))

  // process modular blocks
  const miniMapModule = page.body.modular_blocks.find(
    module => module["minimap"] !== null
  )
  const body = processModules(page.body)

  // add intro text to first module if it is a text area
  let showIntroBlock = true
  const firstBodyBlock = body[0]
  if (firstBodyBlock && firstBodyBlock["text_area"]) {
    let module = firstBodyBlock["text_area"] as TextAreaModule
    module.pageId = page.id
    module.intro = page.intro
    module.miniMap = miniMapModule
    showIntroBlock = false
  }

  return (
    <>
      <div className="itineraryPage">
        <Hero
          heading={page.heading}
          subheading={page.subheading}
          hero_image={page.hero_image}
          id=""
        />

        {/*  Intro */}
        {showIntroBlock && page.intro && (
          <PageIntro
            pageId={page.id}
            intro={page.intro}
            locale={page.publish_details?.locale}
            first={showIntroBlock && page.intro != ""}
            navCodeId=""
            miniMap={
              miniMapModule
                ? (miniMapModule["minimap"] as MiniMapModule)
                : undefined
            }
          />
        )}

        {/* Content */}
        {body.map((modularBlock, index) => {
          return (
            <RenderModularBlock
              key={`modBlock_${index}`}
              block={modularBlock}
              locale={page.publish_details?.locale}
              uid={page.uid}
              first={!(showIntroBlock && page.intro != "")}
              navCodeId=""
              atdwId={page.gathercontent_settings?.atdw_id || ""}
            />
          )
        })}

        {/* Author */}
        {page.author?.length > 0 && (
          <Author {...page.author[0]} locale={props.publish_details?.locale} />
        )}

        {/* Share */}
        <ArticleShare
          title={encodeURIComponent(seoData.metaTitle || "")}
          description={encodeURIComponent(seoData.metaDescription || "")}
          image={encodeURIComponent(seoData.shareImage?.url || "")}
        />

        <ArticleListing
          heading={useDictionary(
            "Article listing module - Heading",
            page.publish_details.locale
          )}
          set={[]}
          intro_paragraph={useDictionary(
            "Itinerary listing module - Intro paragraph",
            page.publish_details.locale
          )}
          include_items={queryRelatedItems(
            page,
            itineraryList,
            page.publish_details.locale
          )}
          read_more_url="/itineraries"
          read_more_text={useDictionary(
            "Itinerary listing module - read more link",
            page.publish_details.locale
          )}
          locale={page.publish_details?.locale}
        ></ArticleListing>
      </div>
      <>
        {parse(page.developer_fields?.code_to_appear_before_close_body || "", {
          htmlparser2: { decodeEntities: true },
        })}
      </>
    </>
  )
}

ItineraryPage.defaultProps = defaultProps

export default ItineraryPage

export function Head(
  props: HeadProps<{
    page: PageModel
  }>
) {
  let page = props.data.page
  // SEO Fields
  const url = getPrettyURL(page).replace(/\/$/, '')
  const langUrl = getLangUrl(
    page.publish_details ? page.publish_details.locale : page.locale
  )
  const seoData: SeoDataModel = {
    slug: url,
    metaTitle: page.seo?.meta_title || page.heading,
    metaTitlePipe: undefined,
    metaDescription: page.seo?.meta_description || page.intro,
    metaKeywords: page.seo?.keywords,
    shareImage:
      page.seo?.share_image || getFirstArrayItem(page.hero_image?.banners)?.banner?.image.file,
    shareTitle: page.seo?.social_share_title,
    shareDescription: page.seo?.social_share_description,
    locale: page.publish_details?.locale,
    hidden: page.seo?.hidden || false,
    canonicalUrl: page.locale !== page.publish_details?.locale ?  url.replace(langUrl, "") : "",
  }
  let banner =
    page.hero_image?.banners &&
    page.hero_image?.banners[0] &&
    page.hero_image?.banners[0].banner
      ? page.hero_image?.banners[0].banner
      : null
  let digitalData = {
    page: cleanObj({
      entityName: page.seo?.meta_title || page.heading,
      entityMessage:
        page.seo?.social_share_description ||
        page.seo?.meta_description ||
        page.intro,
      entityThumbnailUrl:
        banner && banner.image && banner.image.file
          ? getSrc(banner.image.file.gatsbyImageData || "") || ""
          : "",
      pageName:
        "northernterritory.com" +
        getPagePath(page)
          .map(x => x.url)
          .filter(Boolean)
          .join("")
          .replace(/\//g, ":")
          .replace("::", ":"),
      contentLanguage:
        BUILD_LANGS[page.publish_details?.locale as keyof typeof BUILD_LANGS]
          .tracking,
      contentCategory: "itinerary page",
      contentSource: "cms",
      regions:
        page.nt_tags
          ?.filter(x => x.taxonomy === "Regions")
          .map(x => x.display_name.toLowerCase().replace(/ /g, "-"))
          .join(",") || "",
      journeyStage:
        page.nt_tags
          ?.filter(x => x.taxonomy === "Journey Stage")
          .map(x => x.display_name.toLowerCase())
          .join(",") || "",
    }),
    product: {},
    search: {},
  }

  return (
    <>
      <script id="digitalData">{`var digitalData =${JSON.stringify(digitalData)};`}</script>
      <script
        src="//assets.adobedtm.com/launch-ENe48ac8b26e3d48f895a32db0b1702366.min.js"
        data-cfasync="false"
        id="launch"
      ></script>
      <script id="gtm">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-M7XGTM');`}</script>
      <Seo {...seoData} />
      {parse(
        props.data?.page.developer_fields?.code_to_appear_before_close_head ||
          ""
      )}
      <link
        rel="preload"
        as="image"
        type="image/jpeg"
        href={getImageSrc(
          banner && banner.image && banner.image.file
            ? getSrc(banner.image.file.gatsbyImageData || "") || ""
            : "",
          48,
          20
        )}
      ></link>
      <script
        src="https://cdn.jsdelivr.net/npm/algoliasearch@4.14.2/dist/algoliasearch-lite.umd.js"
        integrity="sha256-dImjLPUsG/6p3+i7gVKBiDM8EemJAhQ0VvkRK2pVsQY="
        crossOrigin="anonymous"
      ></script>
      
    </>
  )
}

/******************************************************
  Data
******************************************************/
export const query = graphql`
  fragment ItineraryFields on Contentstack_itinerary {
    id
    uid
    url
    publish_details {
      locale
    }
    parent_page {
      ...PageParentFields
    }
    heading
    subheading
    intro
    hero_image {
      banners {
        banner {
          image {
            file {
              ...CmsImageFields
              gatsbyImageData(
                width: 1920
                layout: FULL_WIDTH
                placeholder: NONE
                quality: 80
              )
            }
            link {
              alt_text
              url
            }
          }
        }
      }
    }
    overrides {
      listing_title
      listing_image {
        ...CmsImageFields
        gatsbyImageData(placeholder: BLURRED, quality: 95)
      }
      menu_title
    }
    seo {
      hidden
    }
    nt_tags {
      ...TagFields
    }
    internal {
      type
    }
  }

  query ItineraryPageByID($pageId: String!) {
    page: contentstackItinerary(id: { eq: $pageId }) {
      id
      url
      uid
      locale
      publish_details {
        locale
      }
      parent_page {
        ...PageParentFields
      }
      gathercontent_settings {
        gathercontent_id
        last_imported
        atdw_id
        sitecore_id
      }
      heading
      subheading
      hero_image {
        rotate_headings
        small_height
        banners {
          banner {
            image {
              file {
                ...CmsImageFields
                gatsbyImageData(
                  width: 1920
                  layout: FULL_WIDTH
                  placeholder: NONE
                  quality: 80
                )
              }
              link {
                alt_text
                url
              }
            }
            video
            image_override
            content_override
            content
            button_text
            button_link
            button_target
            caption
            caption_link
            caption_target
            small
            darken
            render_h1
          }
        }
      }
      intro
      badge {
        ...BadgeFields
      }
      body {
        modular_blocks {
          # text_area
          text_area {
            content
          }
          # gallery
          gallery {
            layout
            images {
              image {
                file {
                  ...CmsImageFields
                  gatsbyImageData(
                    width: 1170
                    placeholder: BLURRED
                    quality: 80
                  )
                }
                link {
                  alt_text
                  url
                }
              }
              caption
              caption_link
              caption_target
            }
          }
          # banner_carousel
          banner_carousel {
            rotate_headings
            small_height
            banners {
              image {
                file {
                  ...CmsImageFields
                  gatsbyImageData(
                    width: 1920
                    layout: FULL_WIDTH
                    placeholder: NONE
                    quality: 80
                  )
                }
                link {
                  alt_text
                  url
                }
              }
              video
              image_override
              content_override
              content
              button_text
              button_link
              button_target
              caption
              caption_link
              caption_target
              small
              darken
              render_h1
            }
          }
          # audio
          audio {
            audio {
              ...AudioFields
            }
          }
          # instagram
          instagram {
            url
          }
          # quote
          quote {
            quote
            author
            image {
              file {
                ...CmsImageFields
                gatsbyImageData(width: 200, placeholder: BLURRED, quality: 80)
              }
              link {
                alt_text
                url
              }
            }
          }
          # stackla
          stackla {
            stackla_filter
            layout
            show_more
            show_spinner
            items_per_page
            limit
            heading
            introduction
          }
          # accordion
          accordion {
            first_open
            accordion_items {
              heading
              content
            }
          }
          # link
          linkitem {
            link {
              ...LinkFields
            }
            breadcrumb
            heading
            intro
            image {
              file {
                ...CmsImageFields
                gatsbyImageData(placeholder: BLURRED, quality: 95)
              }
              link {
                alt_text
                url
              }
            }
          }
          # link_row
          link_row {
            links {
              link {
                ...LinkFields
              }
              content
              image {
                file {
                  ...CmsImageFields
                  gatsbyImageData(placeholder: BLURRED, quality: 95)
                }
                link {
                  alt_text
                  url
                }
              }
            }
          }
          # deals_and_offers
          deals_and_offers {
            listing {
              ...DealsListingFields
            }
          }
          # deals_page_listing
          deals_page_listing {
            listing {
              ...DealsListingFields
            }
          }
          # search_and_book
          search_and_book {
            display
          }
          # editorial_listing
          editorial_listing {
            heading
            intro_paragraph
            layout
            breadcrumbs
            add_highlight
            show_operator_links
            include_links_at_start
            include_immediate_children
            manual_location
            nearby_within_radius
            keywords
            include_items
            exclude_items
            set {
              group {
                set {
                  operator {
                    operator
                  }
                  nt_tags {
                    nt_tags {
                      ...TagFields
                    }
                    operator
                  }
                }
              }
              operator {
                operator
              }
              nt_tags {
                nt_tags {
                  ...TagFields
                }
                operator
              }
            }
          }
          # minimap
          minimap {
            pin_position {
              x
              y
            }
            position {
              x
              y
            }
            starting_position
            highlight_regions
            zoom2
            zoom3
            view_larger_map_link
          }
          # article_listing
          article_listing {
            heading
            intro_paragraph
            read_more_url
            read_more_text
            enable_smart_display_for_itineraries
            enable_smart_display_for_articles
            include_items
            exclude_items
            set {
              group {
                set {
                  operator {
                    operator
                  }
                  nt_tags {
                    nt_tags {
                      ...TagFields
                    }
                    operator
                  }
                }
              }
              operator {
                operator
              }
              nt_tags {
                nt_tags {
                  ...TagFields
                }
                operator
              }
            }
          }
          # dynamic_listing
          dynamic_listing {
            heading
            intro_paragraph
            breadcrumbs
            manual_location
            nearby_within_radius
            keywords
            add_highlight
            show_operator_links
            show_map_list_toggle
            show_map_by_default
            show_region_selector
            display_manual_selection_only
            map
            show_keyword_search
            show_tabs
            show_sub_filters
            filters {
              ...CustomFilterFields
            }
            initial_number_of_rows
            number_of_rows_per_page
            order_by
            disable_add_to_trip
            include_links_at_start
            include_immediate_children
            include_items
            exclude_items
            set {
              group {
                set {
                  operator {
                    operator
                  }
                  nt_tags {
                    nt_tags {
                      ...TagFields
                    }
                    operator
                  }
                }
              }
              operator {
                operator
              }
              nt_tags {
                nt_tags {
                  ...TagFields
                }
                operator
              }
            }
          }
          # atdw_deal_listing
          atdw_deal_listing {
            heading
            intro_paragraph
            filters {
              ...CustomFilterFields
            }
          }
          # button_row
          button_row {
            heading
            intro_paragraph
            links {
              text
              highlight
              style
              url
              asset {
                ...CmsAssetFields
              }
              item
              new_window
            }
          }
          # video_player
          video_player {
            heading
            intro_paragraph
            random_order
            videos {
              ...VideoFields
            }
          }
          # map
          map {
            heading
            intro_paragraph
            tab
            map_settings_url
            lazy_load
            map_id_prefix
            markers
            default_markers
            map_centre {
              latitude
              longitude
            }
            drive_routes
            flight_paths
            default_drive_route
            default_flight_path
            collapse_legend
            show_active_item
          }
          # faqs
          faqs {
            tab
            faqs {
              title
              content
            }
          }
          # functional
          functional {
            content_type
          }
          # custom HTML
          custom_html {
            content
          }
        }
      }
      author {
        ...AuthorFields
      }
      publish_date
      blocks {
        ...AllBlocks
      }
      overrides {
        listing_title
        listing_image {
          ...CmsImageFields
          gatsbyImageData(placeholder: BLURRED, quality: 95)
        }
        menu_title
      }
      seo {
        meta_title
        meta_description
        keywords
        share_image {
          ...CmsImageFields
          gatsbyImageData(placeholder: BLURRED, quality: 95)
        }
        social_share_title
        social_share_description
        hidden
      }
      developer_fields {
        body_classes
        code_to_appear_before_close_head
        code_to_appear_before_close_body
      }
      nt_tags {
        ...TagFields
      }
      internal {
        type
      }
    }
  }
`
