import React from "react"
import { graphql } from "gatsby"

import RichText from "../RichText"

import { AuthorModel } from "../../models/components"
import { useDictionary } from "../../hooks"
import { getSrc } from "gatsby-plugin-image"
import { getImageSrc } from "../../utils/utils_images"

interface Props extends AuthorModel {}

const defaultProps = {}

const Author: React.FC<Props> = props => {
  // console.log("Author :", props)

  const imageData = props.image
    ? getSrc(props.image.gatsbyImageData)
    : undefined

  return (
    <div className="row author-bio">
      <div className="column medium-8 xlarge-7 xlarge-push-2">
        <section>
          <div className="author__profile">
            {imageData && (
              <img
                className="lazyload"
                sizes="auto"
                data-src={getImageSrc(imageData, 224, 224)}
                alt={props.name}
              />
            )}
          </div>
          <div className="author__content">
            <div className="author__action">
              {useDictionary("Author module - Heading", props.locale)}
            </div>
            <div className="author__name">{props.name}</div>

            {/* content */}
            <div className="author__bio">
              <RichText content={props.content} />
            </div>

            {/* website */}
            {props.website && (
              <div className="author__link">
                <a href={props.website} target="_blank">
                  View website
                </a>
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  )
}

Author.defaultProps = defaultProps

export default Author

/******************************************************
	Data
******************************************************/
export const query = graphql`
  fragment AuthorFields on Contentstack_author {
    id
    name
    image {
      ...CmsImageFields
      gatsbyImageData(width: 200, placeholder: BLURRED, quality: 80)
    }
    content
    website
    internal {
      type
    }
  }
`
