import React from "react"
import { Link } from "gatsby"
import LazyLoad from "react-lazy-load"

import { ListingItemModel } from "../../models/common"
import { getImageSrc } from "../../utils/utils_images"

interface Props extends ListingItemModel {
  cssClass?: string
}

const defaultProps = {}

const ArticleCard: React.FC<Props> = props => {
  // console.log("ArticleCard :", props)

  return (
    <Link
      to={props.path}
      className="article__link"
      target={props.new_window ? "_blank" : "_self"}
    >
      <div className="row small-collapse xlarge-uncollapse">
        <div className="small-5 xlarge-12 column">
          <div className="lazyload-wrapper ratio--16-9">
            <img
              className="lazyload"
              sizes="auto"
              data-src={getImageSrc(props.thumbnail, 412, 232)}
              alt={props.heading}
              width="412"
              height="232"
            />
          </div>
        </div>
        <div className="small-7 xlarge-12 column">
          <div className="article__content">
            <h5 className="article__breadcrumb">{props.type}</h5>
            <h4 className="article__title">{props.heading}</h4>
          </div>
        </div>
      </div>
    </Link>
  )
}

ArticleCard.defaultProps = defaultProps

export default ArticleCard
